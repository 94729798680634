import React from "react";
import { graphql } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";

const About = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <>
      <PageWrapper footerDark>
        <Hero title={frontmatter.title}>{frontmatter.subtitle}</Hero>
        <Content
          mission={frontmatter.mission}
          missiontext={frontmatter.missiontext}
          image1={frontmatter.image1}
          image2={frontmatter.image2}
        />
        <Team
          team={frontmatter.team}
          teamdescription={frontmatter.teamdescription}
          members={frontmatter.members}
        />
      </PageWrapper>
    </>
  );
};
export default About;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        mission
        missiontext
        image1 {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team
        teamdescription
        members {
          name
          role
          image {
            childImageSharp {
              fluid(maxWidth: 1075, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
